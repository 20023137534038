export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const CART_START = "CART_START";
export const CART_SUCCESS = "CART_SUCCESS";
export const CART_FAIL = "CART_FAIL";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAIL = "FETCH_FAIL";
export const LOADING_BOOKS = "LOADING_BOOKS";
export const LOADING_MORE_BOOKS = "LOADING_MORE_BOOKS";
export const LOADING_BOOK = "LOADING_BOOK";
export const PAGE_CHANGED = "PAGE_CHANGED";
export const RADIO_BUTTON_CLICK = "RADIO_BUTTON_CLICK";
export const FETCH_BOOK = "FETCH_BOOK";
export const FETCH_BOOK_LOCAL = "FETCH_BOOK_LOCAL";
export const FETCH_CACHE = "FETCH_CACHE";
export const LOAD_MORE = "LOAD_MORE";

export const FETCH_BILLING_ADD = "FETCH_BILLING_ADD";
export const FETCH_SHIPPING_ADD = "FETCH_SHIPPING_ADD";
export const FETCH_ORDER = "FETCH_ORDER";
export const SELECT_CHANGE = "SELECT_CHANGE";
export const START = "START";
export const ERROR = "ERROR";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const SEARCH = "SEARCH";

export const USER_STAFF = "USER_STAFF";
export const UPDATEBOOK_FAIL = "UPDATEBOOK_FAIL";
export const UPDATEBOOK_SUCCESS = "UPDATEBOOK_SUCCESS";

export const AUTH_GRAB_TOKEN_DISTINCT_ID = "AUTH_GRAB_TOKEN_DISTINCT_ID";
